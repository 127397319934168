import "./styles.scss";

import React from "react";
import LazyLoad from "react-lazyload";

import { useLocale } from "context/locale";

import Map from "components/Map";
import ContactForm from "./components/Form";

const Contact = ({ data }) => {
  const { t } = useLocale();
  return (
    <div className="contact">
      <div className="container">
        <div className="contact__details">
          <div className="contact__text">
            <div className="contact__text__item">
              <label>{t("Address")}</label>
              <span>{data?.address}</span>
              <label>{t("Phone")}</label>
              <a href={`tel:${data.phone}`}>{data.phone}</a>
            </div>
            <div className="contact__text__item">
              <label>{t("E-mail")}</label>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
          </div>
          <LazyLoad>
            <Map />
          </LazyLoad>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
