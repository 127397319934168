import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";
import Contact from "page_components/Contact";

const ContactPage = ({
  location,
  data: { contact },
  pageContext: { translations },
}) => {
  return (
    <Layout
      seo={{
        title: contact?.seo?.title || contact.title,
        description: contact?.seo?.metaDesc || "",
      }}
      translations={translations}
      location={location}
      contact_data={contact?.acfContact}
    >
      <PageHeader label={contact?.title} />
      <Contact data={contact.acfContact} />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ($id: Int!) {
    contact: wpPage(databaseId: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
