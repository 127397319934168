import { object, bool, string } from "yup";

let contactFormSchema = object({
  full_name: string().required("Pole wymagane"),
  phone: string()
    .matches(/^\+?1?\d{9,15}$/, "Niepoprawny numer telefonu")
    .required("Pole wymagane"),
  email: string().required("Pole wymagane").email("Niepoprawny email"),
  message: string().required("Pole wymagane"),
  legal1: bool().oneOf([true], "Pole wymagane"),
});

export default contactFormSchema;
